<template>
  <div class="root">
    <!-- <img src="@/assets/mock_point_rule.jpg" width="100%" alt /> -->
    <div class="container head">
      <div class="row justify-content-between cover py-12">
        <div class="col-3 float-left text-left py-2">
          <img class="p-1" alt="logo" width="82" src="@/assets/new_logo.png" />
        </div>
        <div class="col px-0 title text-center">
          คะแนนและสินค้าที่ร่วมรายการ
        </div>
      </div>
      <div v-if="dataStage == 'ok'" class="row">
        <div class="col p-0">
          <nav class="nav nav-tabs nav-fill">
            <a
              :id="'brand_' + it.id"
              v-on:click="tabClick(it.id)"
              v-for="(it, index) in data"
              :key="index"
              :class="'nav-item nav-link'"
              :data-tag="'brand_' + it.id"
            >
              <img width="100%" :src="it.image" alt />
            </a>
          </nav>
        </div>
      </div>
    </div>
    <div v-if="dataStage == 'nodata'" class="text-center mt-3">ไม่พบข้อมูล</div>

    <div class="dataItems px-2">
      <div class="rows" v-for="it in dataItems.products" :key="it.code">
        <div class="card">
          <div class="card-header">
            {{ dataItems.NameEN }} | {{ dataItems.nameTH }}
          </div>
          <img v-lazy="it.image" width="100%" />
          <div class="card-body">
            <b>{{ it.description }}</b>
            <br />
            <div class="point_seg">
              <b>{{ it.point }} คะแนน</b>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center" v-if="itemsStage == 'nodata'">
        ไม่พบข้อมูลรายการนี้
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PointRule",
  data() {
    return {
      dataStage: "loading",
      data: [],
      file_name: "",
      dataItems: [],
      itemsStage: "ok",
    };
  },
  methods: {
    async fetchData() {
      // get shop info
      return await fetch(`${process.env.VUE_APP_API_URL}/point_rules`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.$JWT}`,
        },
      });
    },
    async getData() {
      try {
        const res = await this.fetchData();
        console.log("fetchData status:", res.status);
        let body = [];
        try {
          body = await res.json();
        } catch {
          body = [];
        }
        this.data = body;
        if (this.data.length == 0) {
          this.dataStage = "nodata";
        } else {
          this.dataStage = "ok";
          this.$nextTick(() => {
            this.tabClick(this.data[0].id);
          });
        }

        console.log("fetchData body:", body);
      } catch (error) {
        this.dataStage = "nodata";
        console.log(error);
      }
    },
    tabClick(id) {
      window.scrollTo(0, 0);
      const nav = document.getElementsByClassName("nav-item"); // document.getElementById("brand_" + key).classList.add("active");
      for (let i = 0; i < nav.length; i++) {
        nav[i].classList.remove("active");
      }

      document.getElementById("brand_" + id).classList.add("active");
      this.dataItems = this.data.filter((it) => {
        return it.id == id;
      });
      this.dataItems = this.dataItems[0];
      if (this.dataItems.products.length == 0) {
        this.itemsStage = "nodata";
      } else {
        this.itemsStage = "ok";
      }
    },
  },
  mounted() {
    this.getData();

    // fittext
    window.fitText(document.getElementsByClassName("title")[0], 1.5);

    window.loading(false);
  },
};
</script>

<style>
body {
  background: rgb(204, 204, 204);
}
</style>

<style lang="scss" scoped>
.head {
  position: fixed;
  top: 0;
  z-index: 1;
  max-width: 450px;
}

.cover {
  // background-image: url("~@/assets/cover_main.svg");
  background-size: cover;
  background-image: linear-gradient(to bottom, #22aee5, #091f8e)
}

.title {
  color: rgb(255, 255, 255);
  font-size: calc(120%);
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

nav {
  background: #ffffff;
  flex-wrap: inherit;
  overflow-x: auto;
}

.nav-item {
  padding: 5px;
  img {
    height: 40px;
    width: 40px;
  }
}

.image_brand {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 40px;
}

.dataItems {
  margin: 160px 0 50px 0;
}

.dataItems .rows {
  margin: 20px 0;
}
.card-header {
  padding: 0.2rem 1.25rem;
  margin-bottom: 0;
  color: #ffffff;
  background-color: #0096dc;
  border: none;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.125); */
}

.card {
  border: none;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2);
}

.point_seg {
  font-size: 1.2em;
}

/* .card {
  padding: 15px 10px;
  margin: 15px 0;
  background: #ffffff;
  border-radius: 15px;
} */

/* .card-head {
  background: #0096dc;
} */
</style>