<template>
  <div class="root">
    <div class="container head">
      <div class="row justify-content-between cover">
        <div class="col-3 float-left text-left">
          <img class="mt-2 p-2" alt="logo" width="80" src="@/assets/logo.png" />
        </div>
        <div class="col px-0 title text-center">รายการพิเศษเดือนนี้</div>
      </div>
    </div>
    <div class="dataItems px-2">
      <div v-if="dataStage == 'nodata'" class="text-center mt-3">
        ไม่พบข้อมูล
      </div>
      <div v-if="dataStage == 'error'" class="text-center mt-3">
        ไม่พบข้อมูล!
      </div>
      <div v-if="dataStage == 'loading'">
        <vue-content-loading
          class="py-2"
          v-for="i in 6"
          :key="i + '_'"
          :weight="100"
          :height="300"
        >
          <rect x="1%" y="0" rx="10" ry="10" width="98%" height="100%" />
        </vue-content-loading>
      </div>
      <div class="rows" v-for="it in data" :key="it.id">
        <div class="card">
          <div class="card-header">{{ it.title }}</div>
          <img v-lazy="it.image" width="100%" />
          <div class="card-body" v-html="toHtml(it.description)"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueContentLoading from "vue-content-loading";

export default {
  name: "Promotions",
  components: { VueContentLoading },
  data() {
    return {
      dataStage: "loading",
      data: [],
      file_name: "",
    };
  },
  methods: {
    async fetchData() {
      // get shop info
      return await fetch(`${process.env.VUE_APP_API_URL}/promotions`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.$JWT}`,
        },
      });
    },
    async getData() {
      try {
        const res = await this.fetchData();
        console.log("fetchData status:", res.status);

        if (res.status !== 200) {
          throw "no case";
        }

        let body = [];
        body = await res.json();
        this.data = body.data || [];
        if (this.data.length == 0) {
          this.dataStage = "nodata";
        } else {
          this.dataStage = "ok";
        }

        console.log("fetchData body:", body);
      } catch (error) {
        this.dataStage = "error";
        console.log(error);
      }
    },
    toHtml(val) {
      return val.replace(/\n/g, "<br>").replace(/\\n/g, "<br>");
    },
  },
  mounted() {
    this.getData();

    // fittext
    window.fitText(document.getElementsByClassName("title")[0], 1.3);

    window.loading(false);
  },
};
</script>

<style>
body {
  background: rgb(204, 204, 204);
}
</style>

<style scoped>
.head {
  position: fixed;
  top: 0;
  z-index: 1;
  max-width: 450px;
}

.cover {
  background-image: url("~@/assets/cover_main.svg");
  background-size: cover;
}

.title {
  margin-top: 20px;
  color: rgb(255, 255, 255);
  font-size: 16px;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.5);
}

nav {
  background: #ffffff;
}

.nav-item {
  padding: 5px;
}

.image_brand {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 40px;
}

.dataItems {
  margin: 100px 0 50px 0;
}

.dataItems .rows {
  margin: 20px 0;
}

.card-header {
  padding: 0.2rem 1.25rem;
  margin-bottom: 0;
  color: #ffffff;
  background-color: #0096dc;
  border: none;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.125); */
}

.card {
  border: none;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2);
}

.card-body {
  font-size: 0.9em;
}
</style>