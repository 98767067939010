<template>
  <div class="root">
    <div class="container head">
      <div class="row justify-content-between cover">
        <div class="col-3 float-left text-left">
          <img class="mt-2 p-2" alt="logo" width="80" src="@/assets/logo.png" />
        </div>
        
      </div>
      <h2>
          <div class="title text-center">เตรียมพบกันเร็วๆนี้</div>
      </h2>
    </div>
  </div>
</template>
<script>

export default {
  name: "ComingSoon",
  mounted() {
    window.loading(false);
  },
};
</script>

<style>
body {
  background: rgb(204, 204, 204);
}
</style>

<style scoped>
.head {
  position: fixed;
  top: 0;
  z-index: 1;
  max-width: 450px;
}

.cover {
  background-image: url("~@/assets/cover_main.svg");
  background-size: cover;
}

.title {
  margin-top: 20px;
}

nav {
  background: #ffffff;
}

.nav-item {
  padding: 5px;
}

.image_brand {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 40px;
}

.dataItems {
  margin: 100px 0 50px 0;
}

.dataItems .rows {
  margin: 20px 0;
}

.card-header {
  padding: 0.2rem 1.25rem;
  margin-bottom: 0;
  color: #ffffff;
  background-color: #0096dc;
  border: none;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.125); */
}

.card {
  border: none;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2);
}

.card-body {
  font-size: 0.9em;
}
</style>